/* 
  Styles copied from library
  https://github.com/rstacruz/nprogress/blob/v0.2.0/nprogress.css
*/

#nprogress {
  pointer-events: none;
  --color: rgb(160, 213, 211);
}

#nprogress .bar {
  background: var(--color);
  position: fixed;
  z-index: 1231;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--color), 0 0 5px var(--color);
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}
